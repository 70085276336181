<template>
  <div class="body1">
    <a-breadcrumb>
      <a-breadcrumb-item>
        <router-link :to="{name: 'home'}">{{ $t('home') }}</router-link>
      </a-breadcrumb-item>
      <a-breadcrumb-item>{{ $t('register') }}</a-breadcrumb-item>
    </a-breadcrumb>

    <a-row type="flex" justify="space-around" align="middle" :style="{ margin:'80px auto'}">
      <a-col :span="16" :style="{ textAlign:'center',marginBottom: '30px'}">
        <a-typography-title :level="4">{{ $t('register_welcome') }}</a-typography-title>
        <a-typography-text>{{ $t('register_welcome_desc') }}</a-typography-text>
      </a-col>
      <a-col :span="16" :style="{ marginBottom: '30px'}">
        <a-steps :current="stepIndex" labelPlacement="vertical" size="small">
          <a-step :title="$t('register_info')"/>
          <a-step :title="$t('email_authentication')"/>
          <a-step :title="$t('info_verification')"/>
        </a-steps>
      </a-col>
      <a-col :span="10" v-if="stepIndex===0">
        <a-form name="form-register" layout="horizontal" :colon="false" :hideRequiredMark="true" autocomplete="off"
                :model="formState" :rules="formRules" @finish="submit0">
          <!--a-form-item-- name="CompanyType" style="width: 100%;">
            <a-radio-group v-model:value="formState.CompanyType" size="large" button-style="solid" style="width: 100%;display: flex;justify-content: center;">
              <a-radio-button value="1">{{ $t('enterprise_agency') }}</a-radio-button>
              <a-radio-button value="2">{{ $t('personal_agent') }}</a-radio-button>
            </a-radio-group>
          </a-form-item-->
          <a-form-item>
            <a-typography-text strong v-if="formState.CompanyType == 1">{{ $t('company_info') }}</a-typography-text>
            <a-typography-text strong v-else>{{ $t('personal_info') }}</a-typography-text>
          </a-form-item>
          <a-form-item name="CompanyName" :label="$t('company_name')" v-if="formState.CompanyType == 1">
            <a-input v-model:value="formState.CompanyName"/>
          </a-form-item>
          <a-form-item name="VeriCode" :label="$t('company_city')" v-if="isZHCN">
            <a-select v-model:value="formState.Province" :options="provinceData.map(pro => ({ value: pro }))"
                      show-search style="width:50%"></a-select>
            <a-form-item-rest>
              <a-select v-model:value="formState.City" :options="cities.map(city => ({ value: city }))" show-search
                        style="width:50%"></a-select>
            </a-form-item-rest>
          </a-form-item>
          <a-form-item name="Address" :label="$t('contact_address')" v-if="isZHCN">
            <a-input v-model:value="formState.Address"/>
          </a-form-item>
          <a-form-item name="Tel" :label="$t('contact_tel')" v-if="formState.CompanyType == 1">
            <a-input v-model:value="formState.Tel"/>
          </a-form-item>
          <a-form-item>
            <a-typography-text strong>{{ $t('account_info') }}</a-typography-text>
          </a-form-item>
          <a-form-item name="LoginName" :label="$t('username')">
            <a-input v-model:value="formState.LoginName"/>
          </a-form-item>
          <a-form-item name="LoginPwd" :label="$ta('login|password')">
            <a-input-password v-model:value="formState.LoginPwd"/>
          </a-form-item>
          <a-form-item name="UserName" :label="$t('full_name')">
            <a-input v-model:value="formState.UserName"/>
          </a-form-item>
          <a-form-item name="UserTel" :label="$t('mobile_number')">
            <a-input v-model:value="formState.UserTel"/>
          </a-form-item>
          <a-form-item :style="{textAlign: 'center'}">
            <a-checkbox v-model:checked="accept">
              <span v-if="isZHCN">我已阅读并同意 <a href="/home/terms-service" target="_blank">《服务条款》</a> 及 <a
                  href="/home/booking-rules" target="_blank">《当天房预订规则》</a></span>
              <span v-else>
                I have read and agreed <a href="/home/terms-service" target="_blank">[Service Terms]</a> and <a
                  href="/home/booking-rules" target="_blank">[Same Day Booking Rules]</a>
              </span>
            </a-checkbox>
          </a-form-item>
          <a-form-item>
            <a-button :disabled="!accept" type="primary" html-type="submit" block>
              {{ $t('next') + ', ' + $t('email_authentication') }}
            </a-button>
          </a-form-item>
        </a-form>
      </a-col>
      <a-col :span="10" v-if="stepIndex===1">
        <a-form name="form-email" layout="horizontal" :colon="false" :hideRequiredMark="true" :model="form1State" :rules="form1Rules" autocomplete="off" @finish="submit1">
          <a-form-item name="UserEmail" :label="$t('user_email')">
            <a-input v-model:value="form1State.UserEmail"/>
          </a-form-item>
          <a-form-item name="VeriCode">
            <a-input-group compact>
              <a-input v-model:value="form1State.VeriCode" style="width: 50%" :maxlength="6" :placeholder="$t('vercode')"/>
              <a-button :disabled="!isEmail(form1State.UserEmail) || isDisabled" @click="SendEmailCode" style="width: 50%">{{ sendCodeText }}</a-button>
            </a-input-group>
          </a-form-item>
          <a-form-item>
            <a-button type="primary" html-type="submit" block :loading="submitLoading">{{$ta('submit|register','p')}}</a-button>
          </a-form-item>
        </a-form>
      </a-col>
      <a-col :span="16" v-if="stepIndex===2">
        <a-result
            status="success"
            :title="$t('register_success')"
            :sub-title="$t('register_welcome_desc')">
          <template #extra>
            <a-button @click="$router.push({name:'home'})">{{ $ta('back|home', 'p') }}</a-button>
          </template>
        </a-result>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import {provinceData, cityData} from "@/data/china-city";
import {existLoginName, sendVeriCodeForReg, register} from "@/services/account";
import {isEmail} from "@/utils/util";
import {inject} from "vue";

export default {
  name: "Register",
  data() {
    return {
      selectedMenuKeys: inject('selectedMenuKeys'),
      provinceData,
      cityData,
      stepIndex: 0,
      accept: false,
      sendCodeText: this.$t('send_email_code'),
      maxResendCodeTime: 60,
      resendCodeTime: 60,
      resendTimer: null,
      isDisabled: false,
      submitLoading: false,
      formState: {
        CompanyName: '',
        CompanyType: '1',
        Province: '',
        City: '',
        Address: '',
        Tel: '',
        LoginName: '',
        LoginPwd: '',
        UserName: '',
        UserTel: '',
      },
      formRules: {
        CompanyName: [{
          required: true,
          message: '',
        }],
        Tel: [{
          required: true,
          message: '',
        }],
        LoginName: [{
          required: true,
          asyncValidator: async (_rule, value) => {
            if (value === '') {
              return Promise.reject('');
            } else if (value.length < 8) {
              return Promise.reject(this.$t("loginname_min_length"));
            } else {
              let res = await this.existLN()
              const resData = res.data
              if (resData.Success && resData.Data) {
                return Promise.reject(this.$t("exist_login_name", {s: this.formState.LoginName}))
              } else {
                return Promise.resolve();
              }
            }
          },
        }],
        LoginPwd: [{
          required: true,
          asyncValidator: async (_rule, value) => {
            if (value === '') {
              return Promise.reject('');
            } else if (value.length < 10) {
              return Promise.reject(this.$t("passwords_min_length"));
            } else {
              return Promise.resolve();
            }
          },
        }],
        UserName: [{
          required: true,
          message: '',
        }],
        UserTel: [{
          required: true,
          message: '',
        }],
      },
      form1State: {
        UserEmail: '',
        VeriCode: '',
      },
      form1Rules: {
        UserEmail: [{
          required: true,
          type: 'email',
          message: '',
        }],
        VeriCode: [{
          required: true,
          message: '',
        }],
      },
    }
  },
  computed: {
    cities() {
      if (this.formState.Province === '')
        return []
      else
        return this.cityData[this.formState.Province]
    },
    isZHCN() {
      return this.$store.getters["setting/getLanguage"] === 'zh_CN'
    }
  },
  watch: {
    'formState.Province': function (val) {
      this.formState.City = this.cityData[val][0];
    },
  },
  beforeMount() {
    this.selectedMenuKeys = []
  },
  beforeUnmount(){
    if(this.resendTimer > 0)
      clearInterval(this.resendTimer)
  },
  methods: {
    isEmail,
    existLN(){
      return new Promise((resolve, reject) => {
        existLoginName(this.formState.LoginName).then(res => {
          resolve(res)
        }).catch(error => {
          reject(error)
        })
      })
    },
    submit0() {
      this.stepIndex = 1
    },
    submit1() {
      if(this.formState.CompanyType == 2){
        this.formState.CompanyName = this.formState.UserName
        this.formState.Tel = this.formState.UserTel
      }

      this.submitLoading = true
      let postData = Object.assign(this.formState, this.form1State)
      register(postData).then(res => {
        this.submitLoading = false
        const resData = res.data
        if (resData.Success) {
          this.$message.success(this.$t('success'))
          this.stepIndex = 2
        } else {
          this.$message.error(this.$t('error.' + resData.Code, {s: this.form1State.UserEmail}))
        }
      }).catch(error => {
        console.log(error)
        this.submitLoading = false
      })
    },
    SendEmailCode:function(){
      if(this.form1State.UserEmail === '')
        return
      if(!isEmail(this.form1State.UserEmail))
        return

      this.isDisabled = true
      sendVeriCodeForReg(this.form1State.UserEmail, this.$store.getters["setting/getLanguage"]).then(res => {
        const resData = res.data
        if (resData.Success) {
          this.$message.success(this.$t('send_email_code_success'))
          this.setCountdown()
          this.resendTimer = setInterval(this.setCountdown, 1000)
        } else {
          this.$message.error(this.$t('error.' + resData.Code, {s: this.form1State.UserEmail}))
          this.isDisabled = false
        }
      }).catch(error => {
        console.log(error)
        this.isDisabled = false
      })
    },
    setCountdown: function() {
      if(this.resendCodeTime <= 0) {
        clearInterval(this.resendTimer)
        this.sendCodeText = this.$t('send_email_code')
        this.resendCodeTime = this.maxResendCodeTime
        this.isDisabled = false
      }else{
        this.sendCodeText = this.$t('resend_after_s', {s: this.resendCodeTime})
        --this.resendCodeTime
      }
    }
  }
}
</script>

<style scoped>

</style>